import React, {lazy} from 'react';
import {administrationPages, appConfigs, dashboardMenu, demoPages, generalPages, layoutMenu} from '../menu';

const LANDING = {
    DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
    COMPANIES: lazy(() => import('../pages/dashboard/general-configuration/Companies')),
    COMPANY: lazy(() => import('../pages/dashboard/general-configuration/Company')),
    VAT_REGISTER: lazy(() => import('../pages/dashboard/general-configuration/VatRegister')),
    ADMINS: lazy(() => import('../pages/dashboard/app-administration/users/Admins')),
    TENANTS: lazy(() => import('../pages/dashboard/app-administration/users/Tenants')),
    MANAGE_RENTAL_REQUESTS: lazy(() => import('../pages/dashboard/app-administration/RentalRequests')),
    APARTMENT: lazy(() => import('../pages/dashboard/app-administration/apartments/Apartment')),
    USER: lazy(() => import('../pages/dashboard/app-administration/users/User')),
    BUILDINGS: lazy(() => import('../pages/dashboard/general-configuration/Buildings')),
    BUILDING: lazy(() => import('../pages/dashboard/app-administration/Building')),
    INVENTORY_OBJECTS: lazy(() => import('../pages/dashboard/app-administration/inventory/InventoryObjects')),
    INVENTORY_CATEGORIES: lazy(() => import('../pages/dashboard/app-administration/inventory/InventoryCategories')),
    INVOICES: lazy(() => import('../pages/dashboard/app-administration/finance/Invoices')),
    PROVIDERS: lazy(() => import('../pages/dashboard/app-administration/providers/Providers')),
    UTILITY: lazy(() => import('../pages/dashboard/app-administration/utility/Utility')),
    TICKETS: lazy(() => import('../pages/dashboard/app-administration/tickets/Tickets')),
    TICKETS_SERVICE_ITEM: lazy(() => import('../pages/dashboard/app-administration/tickets/TicketServiceItem')),
    THIRD_PARTY_API: lazy(() => import('../pages/dashboard/app-settings/ThirdPartyConnections')),
    GUESTS: lazy(() => import('../pages/dashboard/app-administration/guests/Guests')),
    APP_SETTINGS: lazy(() => import('../pages/dashboard/app-settings/AppSettings')),
};
const AUTH = {
    PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
    LOGIN: lazy(() => import('../pages/presentation/auth/Login')),
    RESET_PASSWORD: lazy(() => import('../pages/presentation/auth/ForgottenPassword')),
    VERIFY_ACCOUNT: lazy(() => import('../pages/presentation/auth/VerifyAccount')),
    REQUEST_RENTAL: lazy(() => import('../pages/presentation/auth/RentalRequest')),
};

const PUBLIC_INVOICE = {
    INVOICE: lazy(() => import('../pages/presentation/invoice/ViewInvoice')),
};

const presentation = [
    /**
     * Landing
     */
    {
        path: '/companies-configuration/company',
        element: <LANDING.COMPANY/>,
        exact: true
    },
    {
        path: appConfigs.apiConnections.path,
        element: <LANDING.THIRD_PARTY_API />,
        exact: true
    },
    {
        path: appConfigs.appSettings.path,
        element: <LANDING.APP_SETTINGS/>,
        exact: true
    },
    {
       path: '/user',
       element: <LANDING.USER />,
       exact: true
    },
    {
        path: '/apartment',
        element: <LANDING.APARTMENT/>,
        exact: true
    },
    {
        path: '/utilities',
        element: <LANDING.UTILITY/>,
        exact: true
    },
    {
        path: dashboardMenu.dashboard.path,
        element: <LANDING.DASHBOARD/>,
        exact: true,
    },

    {
        path: generalPages.companies.path,
        element: <LANDING.COMPANIES/>,
        exact: true,
    },
    {
        path: generalPages.vatRegister.path,
        element: <LANDING.VAT_REGISTER/>,
        exact: true,
    },
    {
        path: appConfigs.admins.path,
        element: <LANDING.ADMINS/>,
        exact: true,
    },
    {
        path: administrationPages.tenants.path,
        element: <LANDING.TENANTS/>,
        exact: true,
    },
    {
        path: administrationPages.guests.path,
        element: <LANDING.GUESTS/>,
        exact: true,
    },
    {
        path: administrationPages.rentalRequests.path,
        element: <LANDING.MANAGE_RENTAL_REQUESTS/>,
        exact: true
    },
    {
        path: administrationPages.invoices.path,
        element: <LANDING.INVOICES/>,
        exact: true,
    },
    {
        path: administrationPages.inventory.subMenu.tickets.path,
        element: <LANDING.TICKETS/>,
        exact: true
    },
    {
        path: '/ticket-service-items',
        element: <LANDING.TICKETS_SERVICE_ITEM/>,
        exact: true
    },
    {
        path: administrationPages.inventory.subMenu.inventoryObjects.path,
        element: <LANDING.INVENTORY_OBJECTS/>,
        exact: true,
    },
    {
        path: administrationPages.inventory.subMenu.providers.path,
        element: <LANDING.PROVIDERS/>,
        exact: true
    },
    {
        path: generalPages.buildings.path,
        element: <LANDING.BUILDINGS/>,
        exact: true
    },
    {
        path: '/buildings-configuration/building',
        element: <LANDING.BUILDING/>,
        exact: true
    },
    {
        path: '/inventory/categories',
        element: <LANDING.INVENTORY_CATEGORIES />,
        exact: true
    },

    {
        path: demoPages.page404.path,
        element: <AUTH.PAGE_404/>,
        exact: true,
    },
    {
        path: demoPages.login.path,
        element: <AUTH.LOGIN/>,
        exact: true,
    },
    {
        path: demoPages.requestRental.path,
        element: <AUTH.REQUEST_RENTAL/>,
        exact: true,
    },
    {
        path: demoPages.resetPassword.path,
        element: <AUTH.RESET_PASSWORD/>,
        exact: true,
    },

    {
        path: demoPages.verifyAccount.path,
        element: <AUTH.VERIFY_ACCOUNT/>,
        exact: true,
    },

    {
        path: demoPages.invoice.path,
        element: <PUBLIC_INVOICE.INVOICE />,
        exact: true
    },
];
const contents = [...presentation];

export default contents;
